import React,{Component} from 'react'
import { connect } from 'react-redux';
import * as categoryServices from '../../../services/category';
import AddSubCategoryForm from '../category/components/addSubCatForm'
import {reset} from 'redux-form'
import { v1 as uuidv1 } from 'uuid';

class AddSubCategory extends Component{

    state = {
        currentCatID:''
    }

    onChangeGetID = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index]
        let optionattr =  optionElement.getAttribute('data-id');
        this.setState({
            currentCatID:optionattr
        });
    }

    AddSubCategoryHandler = (values,dispatch) => {
           const SubCatID = uuidv1();
           let payload = Object.assign({},values)
           payload.CategoryName = values.CategoryName
           payload.SubCategory = values.SubCategory
           payload.parentID = this.state.currentCatID
           payload.SubCatID = SubCatID
           this.props.addSubCategoryData(payload);
           dispatch(reset("AddSubCategoryForm"));
    }

    componentDidMount(){
        this.props.getCategoryList()
    }

    render(){
        return(
            <>
                <AddSubCategoryForm getCurrentID={(e)=>this.onChangeGetID(e)} onSubmit={this.AddSubCategoryHandler}></AddSubCategoryForm>
            </>
        )
    }

}

const mapStateToProps = state => {
    return{}
}

const mapDispatchToProps = dispatch => ({
    getCategoryList: () => categoryServices.getCategoryList(dispatch),
    addSubCategoryData: (payload) => categoryServices.addSubCategoryData(dispatch,payload) 
})

export default connect(mapStateToProps,mapDispatchToProps)(AddSubCategory)