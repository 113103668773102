import * as commontypes from '../helpers/commonActionTypes';

export const setLoader = flag => {
    return {
        type: commontypes.SET_LOADER,
        loading: flag
    }
}

export const setErrorMsg = flag => {
    return {
        type:commontypes.SET_FAILED_MSG,
        notSuccess:flag
    }
}

export const setSuccessMsg = flag => {
    return {
        type:commontypes.SET_SUCCESS_MSG,
        Success:flag
    }
}

export default {setLoader, setErrorMsg, setSuccessMsg}