import React,{Component} from 'react'
import AddCategoryForm from './components/addCategoryForm'
import { connect } from 'react-redux';
import * as categoryServices from '../../../services/category';
import {reset} from 'redux-form'
import { v1 as uuidv1 } from 'uuid';

class AddCategory extends Component{

    addCategoryHandler = (values, dispatch) => {
        const subCatid = uuidv1();
        let payload = Object.assign({},values)
        payload.Category = values.Category
        payload.parentID = 0
        payload.ID = subCatid
        this.props.addCategoryData(payload);
        dispatch(reset("AddCategoryForm"));
    };

    render(){
        return(
            <>
                <AddCategoryForm onSubmit={this.addCategoryHandler}></AddCategoryForm>
            </>
        )
    }
}

const mapStateToProps = state => {
    return{
    }
}

const mapDispatchToProps = dispatch => ({
    addCategoryData: (payload) => categoryServices.addCategoryData(dispatch, payload) 
})

export default connect(mapStateToProps,mapDispatchToProps)(AddCategory)
