import React from 'react'
import {Alert} from 'react-bootstrap'

const CustomMsg = (props) => {
    return(
        <>
            <Alert variant={props.variant}>{props.msg}</Alert>
        </>
    )
}

export default CustomMsg