import * as type from '../../../../modules/admin/category/actionTypes/category.action';

const initialState = {
    showSuccessMsg:false,
    CategoryList:[],
    SubCategoryList:[]
}

const Category = (state = initialState, action) => {
    switch(action.type){
        case type.SUCCESS_ADD_MSG:
            return{
                ...state,
                showSuccessMsg:action.addedstatus
            }
        case type.GET_CATEGORY:
            return{
                ...state,
                CategoryList:action.categoryList
            }
        case type.GET_SUB_CAT:
            return{
                ...state,
                SubCategoryList:action.subcategoryList
            }
        default:
            return state
    }
}


export default Category