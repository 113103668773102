import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { Switch, Route, HashRouter} from 'react-router-dom'
import '../src/assets/css/global.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import AdminHeader from './hoc/adminHeader'
import AppRoutes from './appRoutes'
import {AuthProvider} from './services/authentication'

class App extends Component{
  render() {
    return (
        <React.Fragment>
          <AuthProvider>
          <HashRouter>
            <AdminHeader></AdminHeader>
            <Switch>
              <Route path="/" component={AppRoutes}></Route>
            </Switch>
            <Backdrop open={this.props.loading}>
              <CircularProgress  color="inherit" />
            </Backdrop>
          </HashRouter>
          </AuthProvider>
        </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { common } = state;
  return {
    loading:common.loading || false
  }
}

export default connect(mapStateToProps)(App);
