import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {ButtonGroup,Button} from 'react-bootstrap'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const SubCatListTable = (props) => {

    const columns = [
        { id: 'Sub Category', label: 'Sub Category', minWidth: 170 },
        { id: 'Category', label: 'Parent Category', minWidth: 170 },
        { id: 'Action', label: 'Action', minWidth: 170 },
    ];

    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    return(
    <>
          <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.MainSubCatList && props.MainSubCatList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <>
                    <TableRow hover key={row.keyNode} role="checkbox" tabIndex={-1}>
                          <TableCell>{row.SubCategory}</TableCell>
                          <TableCell>{row.CategoryName}</TableCell>
                          <TableCell>
                            <ButtonGroup className="mb-2">
                              <Button onClick={(e) => props.getSubCatUpdateId(e)} id={row.SubCatID} keynode={row.keyNode}>Edit</Button>
                              <Button onClick={(e) => props.getSubCatDeleteId(e)} variant="danger" id={row.SubCatID} keynode={row.keyNode}>Delete</Button>
                            </ButtonGroup>
                          </TableCell>
                    </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.MainSubCatList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
    </>
    )
}

export default SubCatListTable