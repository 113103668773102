import React from 'react'

const CustomInputField = ({input, type, autoComplete, min, max, autoFocus, readOnly, name, label, placeholder, disabled, maxLength, minLength, required, className, ...custom }) => (
    <>
    {
        <input
            type={type}
            placeholder={placeholder || ''}
            autoComplete={autoComplete || 'off'}
            autoFocus={autoFocus || false}
            min={min || ''}
            max={max || ''}
            readOnly={readOnly || false}
            name={name}
            required={required || false}
            inputprops={{ maxLength: maxLength ? maxLength : 255 }}
            disabled={disabled || false}
            {...input}
            {...custom}
            className={className || ''}
        />
    }
    </>
)

export default CustomInputField