import React,{Component} from 'react'
import {connect} from 'react-redux'
import { Field, reduxForm } from 'redux-form';
import {Card, Form, Button, Container, Row, Col} from 'react-bootstrap'
import CustomInputField from '../../../../sharedComponents/customInputField'
import CustomDropDownField from '../../../../sharedComponents/customDropDown'
import CustomMsg from '../../../../sharedComponents/customMsg'

class AddProductForm extends Component{

    render(){
        const {handleSubmit} = this.props;
        return(
            <>
            <Container className="d-flex align-items-center justify-content-center" style={{height:'100vh'}}>
                <Card className="login-form">
                    <Card.Body>
                    <h2 className="text-center mb-4">Add Product</h2>
                    {this.props.ProductAddedError && <CustomMsg msg='Something went wrong' variant='danger'></CustomMsg>}
                    {this.props.ProductAdded && <CustomMsg msg='Product Added Succesfully' variant='success'></CustomMsg>}
                    <Form onSubmit={handleSubmit}>
                        <Row>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Select Category</Form.Label>
                                <Field className="form-control" name="CategoryName" onChange={this.props.GetCatID} component={CustomDropDownField} required>
                                    <option value="">Please select</option>
                                    {this.props.categoryList && this.props.categoryList.map(x => <option key={x.ID} data-id={x.ID} value={x.Category}>{x.Category}</option>)}
                                </Field>
                            </Form.Group>
                        </Col>
                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Select Sub Category</Form.Label>
                                <Field className="form-control" name="SubCategoryName" onChange={this.props.GetSubCatID} component={CustomDropDownField}>
                                    <option value="">Please select</option>
                                    {this.props.subcatlist && this.props.subcatlist.map(x => <option key={x.SubCatID} data-id={x.SubCatID} value={x.SubCategory}>{x.SubCategory}</option>)}
                                </Field>
                            </Form.Group>
                        </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Product Name</Form.Label>
                                    <Field placeholder="Product Name" className="form-control" name="ProductName" component={CustomInputField} required/>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Item Code</Form.Label>
                                    <Field placeholder="Item Code" className="form-control" name="ProductCode" component={CustomInputField} required/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Field placeholder="Description" className="form-control" name="Description" component={CustomInputField} required/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Images</Form.Label>
                            <input id="ProductImage" type="file" onChange={this.props.GetImages} accept="image/jpeg, image/jpg, image/png," required multiple />
                        </Form.Group>
                        <Button className="w-100" type="submit">Add Product</Button>
                    </Form>
                    </Card.Body>
                </Card>
            </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return{
        categoryList:state.categoryReducer.CategoryList,
        subcatlist:state.AddProductRed.SubCatItem,
        ProductAdded:state.AddProductRed.ProStatus,
        ProductAddedError:state.AddProductRed.ProductAddedError
    }
}

export default connect(mapStateToProps)(reduxForm({form:'AddProductForm'})(AddProductForm))