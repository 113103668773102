import * as type from '../../addProduct/actionTypes/product.action'

const initialState = {
    SubCatItem:[],
    ProStatus:false,
    ProductAddedError:false,
    ProductList:[]
}

const AddProduct = (state = initialState, action) => {
    switch(action.type){
        case type.GETSUBCATITEM:
            return{
                ...state,
                SubCatItem:action.setSubCat
            }
        case type.PRODUCT_ADDED:
            return{
                ...state,
                ProStatus:action.ProductAdded
            }
        case type.PRODUCT_ADDED_ERROR:
            return{
                ...state,
                ProductAddedError:action.ProductAddedError
            }
        case type.GET_PRODUCT_LIST:
            return{
                ...state,
                ProductList:action.ProductList
            }
            default:
                return state
    }
}

export default AddProduct
