let apiUrls = {
    baseUrl:'https://crown-db-bf94a-default-rtdb.firebaseio.com/Product-Database',
    live:{
        category:{
            addCategory:{
                url:'/Category'
            },
            addSubcategory:{
                url:'/Sub-Category'
            },
            getCategoryList:{
              url:'/Category'
            },
            getSubcategoryList:{
              url:'/Sub-Category'
          },
        },
        product:{
          addProducts:{
            url:'/Products'
          },
          getProducts:{
            url:'/Products'
          }
        }
    }
}

function getApiUrl(key, name) {
    let url;
    if (apiUrls.baseUrl) {
      if (apiUrls.live[key] && apiUrls.live[key][name]) {
        url = apiUrls.baseUrl + apiUrls.live[key][name]['url'];
      } 
    }
    return url;
  }
export default getApiUrl;