import axios from 'axios'
import getApiUrl from '../helpers/apiURLs'
import * as commonServices from '../services/commonServices'
import * as CategoryType from '../modules/admin/category/actionTypes/category.action'
import {database, auth} from '../helpers/firebase-config'

// ADD Category
export const addCategoryData = (dispatch, payload) => {
    auth.currentUser.getIdToken().then(token => {
        if (token) {
            dispatch(commonServices.setLoader(true));
            const url = getApiUrl('category','addCategory') + ".json?auth=" + token;
            const str = JSON.stringify(payload)
            axios.post(url, str).then( response => {
                if(response.status){
                    dispatch(commonServices.setLoader(false))
                    dispatch({type: CategoryType.SUCCESS_ADD_MSG, addedstatus: true});
                    setTimeout(() => {
                        dispatch({type: CategoryType.SUCCESS_ADD_MSG, addedstatus: false});
                      }, 2000)
                }
                else{
                    dispatch(commonServices.setLoader(false))
                }
            }).catch(err => dispatch(dispatch(commonServices.setLoader(false))))
        }
    })
}

// GET Category List
export const getCategoryList = (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const url = getApiUrl('category','getCategoryList') + ".json"
    axios.get(url).then(response => {
    if(response.status){
        
        let categoryArr = []
        let respnseData = response.data || {}
        for (const key of Object.keys(respnseData)) {
            var data = respnseData[key]
            data.keyNode = key
            categoryArr.push(data);
        }
        dispatch(commonServices.setLoader(false))
        dispatch({type:CategoryType.GET_CATEGORY, categoryList:categoryArr})
    }
    else{
        dispatch(commonServices.setLoader(false))
    }
    }).catch(err => dispatch(dispatch(commonServices.setLoader(false))))

}

// Add Sub Category

export const addSubCategoryData = (dispatch, payload) => {

    auth.currentUser.getIdToken().then(token => {
        if (token) {
            dispatch(commonServices.setLoader(true));
            const url = getApiUrl('category','addSubcategory') + ".json?auth=" + token
            axios.post(url,payload).then(response => {
                console.log(response)
            if(response.status){
                dispatch(commonServices.setLoader(false))
                dispatch({type: CategoryType.SUCCESS_ADD_MSG, addedstatus: true});
                setTimeout(() => {
                    dispatch({type: CategoryType.SUCCESS_ADD_MSG, addedstatus: false});
                }, 2000)
            }
            else{
                dispatch(commonServices.setLoader(false))
            }
            }).catch(err => dispatch(dispatch(commonServices.setLoader(false))))
        }
    })

}

// Delete Category

export const CheckSubCatories = (dispatch, CatDeleteID, CatNode, subCatFalse) => {
    dispatch(commonServices.setLoader(true));
    const getSubCatQuery = database.ref('Product-Database').child('Sub-Category').orderByChild("parentID").equalTo(CatDeleteID);
    getSubCatQuery.once('value').then( (snapshot) => {
        let SubCat = snapshot.val()
        if(SubCat === null){
            CheckProducts(dispatch,CatDeleteID,CatNode, subCatFalse)
        }
        else{
            dispatch(commonServices.setLoader(false));
            dispatch(commonServices.setErrorMsg(true));
            setTimeout(() => {
                dispatch(commonServices.setErrorMsg(false))
            }, 2000)
        }
    }).catch(err=>{});
}

// Check products

export const CheckProducts = (dispatch, CatDeleteID, CatNode, SubCatTrue) => {
    dispatch(commonServices.setLoader(true));
    let getProductQuery;
    if(SubCatTrue === true){
        getProductQuery = database.ref('Product-Database').child('Products').orderByChild("SubCatID").equalTo(CatDeleteID);
    }
    else{
        getProductQuery = database.ref('Product-Database').child('Products').orderByChild("CatID").equalTo(CatDeleteID);
    }
    getProductQuery.once('value').then( (snapshot) => {
        let productCount = snapshot.val()
        if(productCount === null){
            DeleteCat(dispatch, CatNode, SubCatTrue)
        }
        else{
            dispatch(commonServices.setLoader(false));
            dispatch(commonServices.setErrorMsg(true));
            setTimeout(() => {
                dispatch(commonServices.setErrorMsg(false))
            }, 2000)
        }
    }).catch(err=>{
        dispatch(commonServices.setLoader(false));
    });
}

const DeleteCat = (dispatch, CatNode, SubCatTrue) => {
    if(SubCatTrue === true){
        database.ref('Product-Database').child('Sub-Category').child(CatNode).remove()
    }
    else{
        database.ref('Product-Database').child('Category').child(CatNode).remove()
    }
    
    getCategoryList(dispatch)
    getSubCatAllItem(dispatch)
    dispatch(commonServices.setLoader(false));
    dispatch({type: CategoryType.SUCCESS_ADD_MSG, addedstatus: true});
    setTimeout(() => {
        dispatch({type: CategoryType.SUCCESS_ADD_MSG, addedstatus: false});
    }, 2000)
}

// Get Sub Categories

export const getSubCatAllItem = (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const url = getApiUrl('category','getSubcategoryList') + ".json"
    axios.get(url).then(response => {
    if(response.status){
        let subcategoryArr = []
        let respnseData = response.data || {}
        for (const key of Object.keys(respnseData)) {
            var data = respnseData[key]
            data.keyNode = key
            subcategoryArr.push(data);
        }
        dispatch(commonServices.setLoader(false))
        dispatch({type:CategoryType.GET_SUB_CAT, subcategoryList:subcategoryArr})
    }
    else{
        dispatch(commonServices.setLoader(false))
    }
    }).catch(err => dispatch(dispatch(commonServices.setLoader(false))))
}

export default {
    addCategoryData,
    getCategoryList,
    addSubCategoryData,
    CheckSubCatories,
    getSubCatAllItem
}