import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {Card, Form, Button, Container, Alert} from 'react-bootstrap'
import CustomInputField from '../../../../sharedComponents/customInputField'
import CustomDropDownField from '../../../../sharedComponents/customDropDown'

class AddSubCategoryForm extends Component{

    render(){
        const {handleSubmit} = this.props;
        return(
            <>
            <Container className="d-flex align-items-center justify-content-center" style={{height:'100vh'}}>
                <Card className="login-form">
                    <Card.Body>
                    <h2 className="text-center mb-4">Add Sub Category</h2>
                    {this.props.categoryAddedSuccess &&   
                    <Alert variant='success'>
                        Sub Category Added Successfully
                    </Alert>
                    }
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Select Category</Form.Label>
                            <Field className="form-control" name="CategoryName" onChange={this.props.getCurrentID} component={CustomDropDownField} required>
                                <option value="">Please select</option>
                                {this.props.categoryList.map(x => <option key={x.ID} data-id={x.ID} value={x.Category}>{x.Category}</option>)}
                            </Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Sub Category Name</Form.Label>
                            <Field placeholder="Add Category" className="form-control" name="SubCategory" component={CustomInputField} required/>
                        </Form.Group>
                        <Button className="w-100" type="submit">Add</Button>
                    </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
        )
    }
}

const mapStateToProps = state => {
    return{
         categoryList:state.categoryReducer.CategoryList,
         categoryAddedSuccess:state.categoryReducer.showSuccessMsg
    }
}

export default connect(mapStateToProps)(reduxForm({form:'AddSubCategoryForm'})(AddSubCategoryForm))