import { combineReducers } from "redux";
import commonReducer from "./helpers/commonReducer";
import { reducer as formReducer } from 'redux-form';
import { i18nReducer } from 'redux-react-i18n';
import categoryReducer from './modules/admin/category/reducer/category.reducer'
import AddProductRed from './modules/admin/addProduct/reducer/product.reducer'

const rootreducer = combineReducers({
    common: commonReducer,
    categoryReducer:categoryReducer,
    AddProductRed:AddProductRed,
    form: formReducer,
    i18nReducer: i18nReducer
});

export default rootreducer;