import React,{Component} from 'react'
import {connect} from 'react-redux'
import{Tabs,Tab, Container, Row, Col} from 'react-bootstrap'
import CatListTable from './components/CatListTable'
import SubCatListTable from './components/SubCatListTable'
import * as categoryServices from '../../../services/category';
import CustomMsg from '../../../sharedComponents/customMsg'

class CategoriesList extends Component {

    DeleteCatHandler = (e) => {
        let getId = e.target.id
        let getnode = e.target.getAttribute('keynode')
        let subCatFalse = false
        this.props.CheckSubCatories(getId,getnode,subCatFalse)
    }

    DeleteSubCathandler = (e) => {
        let getId = e.target.id
        let getnode = e.target.getAttribute('keynode')
        let subCatTrue = true
        this.props.CheckProducts(getId,getnode,subCatTrue)
    }

    UpdateSubCathandler = (e) => {
        let getId = e.target.id
        let getnode = e.target.getAttribute('keynode')
        console.log(getId,getnode)
    }

    componentDidMount(){
        this.props.getCategoryList()
        this.props.getSubCatAllItem()
    }

    render(){
        return(
            <>
                {this.props.categoryAddedSuccess && <CustomMsg msg="Record Deleted Succesfully" variant="success"/>}
                {this.props.showErrorMsg && <CustomMsg msg="Delete Sub Categories/Products related to this category" variant="danger"/>}
                <Container className="marTop50">
                    <Row>
                        <Col>
                            <Tabs defaultActiveKey={1}>
                                <Tab eventKey={1} title="Categories">
                                    <CatListTable getDeleteId={this.DeleteCatHandler} MainCatList={this.props.categoryList}></CatListTable>
                                </Tab>
                                <Tab eventKey={2} title="Sub Categories">
                                    <SubCatListTable getSubCatUpdateId={this.UpdateSubCathandler} getSubCatDeleteId={this.DeleteSubCathandler} MainSubCatList={this.props.subcategoryList}></SubCatListTable>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>              
            </>
        )
    }
}

const mapStateToProps = state => {
    return{
         categoryList:state.categoryReducer.CategoryList,
         subcategoryList:state.categoryReducer.SubCategoryList,
         categoryAddedSuccess:state.categoryReducer.showSuccessMsg,
         showErrorMsg:state.common.notSuccess
    }
}

const mapDispatchToProps = dispatch => ({
    getCategoryList: () => categoryServices.getCategoryList(dispatch),
    CheckSubCatories:(getId,getnode,subCatFalse) => categoryServices.CheckSubCatories(dispatch,getId,getnode,subCatFalse),
    getSubCatAllItem:() => categoryServices.getSubCatAllItem(dispatch),
    CheckProducts:(getId,getnode,subCatTrue) => categoryServices.CheckSubCatories(dispatch,getId,getnode,subCatTrue)
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList)
