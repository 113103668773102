import * as type from './commonActionTypes';

const initialState = {
    loading: false,
    notSuccess:false,
    success:false
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.SET_LOADER:
            return {
                ...state,
                loading: action.loading
            }
        case type.SET_FAILED_MSG:
            return{
                ...state,
                notSuccess:action.notSuccess
            }
        case type.SET_SUCCESS_MSG:
            return{
                ...state,
                success:action.Success
            }
        default:
            return state
    }
}

export default commonReducer;