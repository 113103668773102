import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {ButtonGroup,Button} from 'react-bootstrap'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export default function StickyHeadTable(props) {
const columns = [
    { id: 'Category', label: 'Category', minWidth: 170 },
    { id: 'Sub Category', label: 'Sub Category', minWidth: 170 },
    { id: 'Product Name', label: 'Product Name', minWidth: 170 },
    { id: 'ItemCode', label: 'ItemCode', minWidth: 170 },
    { id: 'Description', label: 'Description', minWidth: 200 },
    { id: 'Images', label: 'Images', minWidth: 100 },
    { id: 'Action', label: 'Action', minWidth: 100 }
];

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.productDataList && props.productDataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <>
                <TableRow id={row.SubCatID} key={row.keyNode} hover role="checkbox" tabIndex={-1}>
                      <TableCell>{row.CategoryName}</TableCell>
                      <TableCell>{row.SubCategoryName}</TableCell>
                      <TableCell>{row.ProductName}</TableCell>
                      <TableCell>{row.ProductCode}</TableCell>
                      <TableCell>
                        <div className='proDes'>{row.Description}</div>
                      </TableCell>
                      <TableCell>
                        <div className="proImgCon">
                            {row.imgURLs.map((url,index) => {
                              return(
                                <div className="proimg" key={index}>
                                  <img src={url} alt={row.ProductName} title={row.ProductName}/>
                                </div>
                              )
                            })}
                        </div>
                      </TableCell>
                      <TableCell>
                        <ButtonGroup className="mb-2">
                          {/* <Button>Edit</Button> */}
                          <Button onClick={(e) => props.removeRecord(e)} variant="danger" keynode={row.keyNode} parentid={row.CatID} id={row.SubCatID}>Delete</Button>
                        </ButtonGroup>
                      </TableCell>
                      </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.productDataList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
