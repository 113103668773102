import React,{Component} from 'react'
import {connect} from 'react-redux'
import ProductTable from './components/productTable'
import CustomMsg from '../../../sharedComponents/customMsg'
import * as productReqSer from '../../../services/product'

class ProductList extends Component{

    constructor(props){
        super(props)
        this.state = {
            ProductSearch:false,
            ProductFilterData:''
        }
    }

    DeleteProductHandler = (e) => {
        let getId = e.target.id
        let getnode
        let SubCatIDStatus
        if(getId){
            getId = e.target.id
            getnode = e.target.getAttribute('keynode')
            SubCatIDStatus = true
        }
        else{
            getId = e.target.getAttribute('parentid')
            getnode = e.target.getAttribute('keynode')
            SubCatIDStatus = false
        }
        this.props.DeleteProduct(getId,getnode,SubCatIDStatus)
    }

    SearchProduct = (e) => {
        let currentvalue = e.target.value;
        let productArrayList = this.props.productDataList
        if(currentvalue.length > 1){
            const filteredData = productArrayList.filter(value => {
                const searchStr = currentvalue.toLowerCase()
                const ProductNameMatch = value.ProductName.toLowerCase().includes(searchStr)
                return ProductNameMatch
            })
            this.setState({
                ProductSearch:true,
                ProductFilterData:filteredData,
            })
        }
        else{
            this.setState({
                ProductSearch:false
            })
        }
    }

    componentDidMount(){
        this.props.getProductList();
    }

    render(){
        let productListArray = ''
        if(this.state.ProductSearch){
            productListArray = this.state.ProductFilterData
        }
        else{
            productListArray = this.props.productDataList
        }
        return(
            <>
                {this.props.showErrorMsg && <CustomMsg msg="Something Went Wrong" variant="danger"/>}
                {this.props.showSuccessMsg && <CustomMsg msg="Record Deleted Succesfully" variant="success"/>}
                <div class="col-sm-12">
                    <form>
                        <div className="form-group">
                            <ul className="search-product">
                                <li><label class="form-label">Search By Product Name</label></li>
                                <li><input class="form-control" type="text" onChange={(e)=>this.SearchProduct(e)}/></li>
                            </ul>
                        </div>
                    </form>
                </div>
                {/* <div className="search-product">
                    <ul>
                        <li>Search by Product Name</li>
                        <li><input type="text" onChange={(e)=>this.SearchProduct(e)}/></li>
                    </ul>
                </div> */}
                <ProductTable removeRecord={this.DeleteProductHandler} productDataList={productListArray}></ProductTable>
            </>
        )
    }
}

const mapStateToProps = state => {
    return{
        productDataList:state.AddProductRed.ProductList,
        showErrorMsg:state.common.notSuccess,
        showSuccessMsg:state.common.success
    }
}

const mapDispatchToProps = dispatch => ({
    getProductList:() => productReqSer.getProductList(dispatch),
    DeleteProduct:(getId,getnode,SubCatIDStatus) => productReqSer.DeleteProduct(dispatch,getId,getnode,SubCatIDStatus)
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)