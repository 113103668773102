import { applyMiddleware, compose, createStore } from 'redux';
import promiseMiddleware from "redux-promise";
import thunk from 'redux-thunk';
import config from './global/config';
import middlewares from './global/middlewares';
import reducer from './rootReducer';

const { isDev, isBrowser } = config;
const devtools = isDev && isBrowser && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__ : () => (fn) => fn;

const configureStore = (initialState = {}, services = {}) => {
    const enhancers = [ applyMiddleware(...middlewares, thunk, promiseMiddleware), devtools() ];
    const store = createStore(reducer, initialState, compose(...enhancers));
    return store;
};

export default configureStore;