import React, {useEffect, useRef, useState} from 'react';
import {Card, Form, Button, Container, Alert} from 'react-bootstrap'
import {useAuth} from '../../../services/authentication'
import {useHistory} from 'react-router-dom'


const Login = () => {

    const emailRef = useRef()
    const passwordRef = useRef()
    const {signIn} = useAuth()
    const [Error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const { currentUser } = useAuth();

    async function handleSubmit(e){
        e.preventDefault()
        try {
            setLoading(true)
            setError('')
            await signIn(emailRef.current.value, passwordRef.current.value)
            history.push('/admin/dashboard')
        } catch {
            setError('Invalid Email Account')
        }
        setLoading(false)
    }

    useEffect(()=>{
        if(currentUser===null){
            history.push('/')
        }
        else{
            history.push('/admin/dashboard')
        }
    },[])

    return(
        <>
            <Container className="d-flex align-items-center justify-content-center" style={{height:'100vh'}}>
                <Card className="login-form">
                    <Card.Body>
                        <h2 className="text-center mb-4">Login</h2>
                        {Error && <Alert variant="danger">{Error}</Alert>}
                        <Form>
                            <Form.Group id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" ref={emailRef} required></Form.Control>
                            </Form.Group>
                            <Form.Group id="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" ref={passwordRef} required></Form.Control>
                            </Form.Group>
                            <Button disabled={loading} onClick={(e) => handleSubmit(e)} className="w-100" type="submit">Sign In</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}

export default Login