import React, { Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {Card, Form, Button, Container, Alert} from 'react-bootstrap'
import CustomInputField from '../../../../sharedComponents/customInputField'

class AddCategoryForm extends Component{
    render(){
        const {handleSubmit} = this.props;
        return(
            <>
            <Container className="d-flex align-items-center justify-content-center" style={{height:'100vh'}}>
                <Card className="login-form">
                    <Card.Body>
                    <h2 className="text-center mb-4">Add Category</h2>
                    {this.props.categoryAddedSuccess &&   
                    <Alert variant='success'>
                        Category Added Successfully
                    </Alert>
                    }
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Category Name</Form.Label>
                            <Field placeholder="Add Category" className="form-control" name="Category" component={CustomInputField} required/>
                        </Form.Group>
                        <Button className="w-100" type="submit">Add</Button>
                    </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
        )
    }
}

const mapStateToProps = state => {
    return{
        categoryAddedSuccess:state.categoryReducer.showSuccessMsg
    }
}

export default connect(mapStateToProps)(reduxForm({form:'AddCategoryForm'})(AddCategoryForm))