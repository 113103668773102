import React from 'react'

const Dashbaord = () => {
    return(
        <div className="dashboardLink">
            <h1>Welcome To Dashboard</h1>
        </div>
    )
}

export default Dashbaord