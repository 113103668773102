import React,{Component} from 'react'
import {connect} from 'react-redux'
import {reset} from 'redux-form'
import AddProductForm from './components/addProductForm'
import * as categoryServices from '../../../services/category'
import * as productReqSer from '../../../services/product'
import { v1 as uuidv1 } from 'uuid';

class AddProduct extends Component{

    state = {
        GetCatID:'',
        GetSubCatID:'',
        uploadFile:''
    }

    handleChange(event) {
        const file = Array.from(event.target.files);
        this.setState({ uploadFile:file });   
    }

    GetCatID = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index]
        let optionattr =  optionElement.getAttribute('data-id');
        this.props.getSubCatItem(optionattr)
        this.setState({
            GetCatID:optionattr
        });
    }

    GetSubCatID = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index]
        let optionattr =  optionElement.getAttribute('data-id');
        this.setState({
            GetSubCatID:optionattr
        })
    }

    AddProductHandler = (values,dispatch) => {
        let imageUrl = this.state.uploadFile
        const productID = uuidv1();
        let payload = Object.assign({},values)
        payload.CategoryName = values.CategoryName
        payload.SubCategoryName = values.SubCategoryName
        payload.ProductName = values.ProductName
        payload.ProductCode = values.ProductCode
        payload.Description = values.Description
        payload.CatID = this.state.GetCatID
        payload.SubCatID = this.state.GetSubCatID
        payload.ProductID = productID
        this.props.addProduct(imageUrl,payload);
        dispatch(reset('AddProductForm'))
        // console.log(payload)
        // console.log(imageUrl)
    }

    componentDidMount(){
        this.props.getCategoryList()
    }

    render(){
        return(
            <>
                <AddProductForm GetImages={(e)=>this.handleChange(e)} GetCatID={(e)=>this.GetCatID(e)} GetSubCatID={(e)=>this.GetSubCatID(e)} onSubmit={this.AddProductHandler}></AddProductForm>
            </>
        )
    }
}

const mapStateToProps = state => {
    return{
    }
}

const mapDispatchToProps = dispatch => ({
    getCategoryList: () => categoryServices.getCategoryList(dispatch),
    getSubCatItem:(CatID) => productReqSer.getSubCatItem(dispatch,CatID),
    addProduct: (uploadImg,payload) => productReqSer.addProduct(dispatch,uploadImg,payload)
})

export default connect(mapStateToProps,mapDispatchToProps)(AddProduct) 