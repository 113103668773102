import React from 'react';
import {Navbar,Nav} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useAuth} from '../services/authentication'


const AdminHeader = () => {
  const { currentUser } = useAuth();
  return (
    <>
    { currentUser ?
    <Navbar bg="dark" variant="dark">
    <Navbar.Brand>Product Catalogue</Navbar.Brand>
        <Nav className="mr-auto">
            <Link className="nav-link" to="/admin/dashboard">Dashboard</Link>
            <Link className="nav-link" to="/admin/add-category">Add Category</Link>
            <Link className="nav-link" to="/admin/add-sub-category">Add Sub Category</Link>
            <Link className="nav-link" to="/admin/categories-list">Categories List</Link>
            <Link className="nav-link" to="/admin/add-product">Add product</Link>
            <Link className="nav-link" to="/admin/product-list">Product List</Link>
            <Link className="nav-link" to="/admin/logout">Logout</Link>
        </Nav>
    </Navbar>
    : ''
    }
    </>
  );
}

export default AdminHeader