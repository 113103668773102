import React,{useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {auth} from '../../../helpers/firebase-config'

const Logout = () => {
    const history = useHistory();
    useEffect(()=>{
        auth.signOut().then(function() {
            history.push("/admin/login");
          }, function(error) {
            // An error happened.
          });
    },[])

    return(
        <>
        </>
    )
}

export default Logout