import React,{Component} from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from './sharedComponents/privateRoute';
import Login from './modules/admin/login/login';
import Dashbaord from './modules/admin/dashboard/dashboard';
import AddCategory from './modules/admin/category/addCategory'
import AddSubCategory from './modules/admin/category/addSubCat'
import Addproduct from './modules/admin/addProduct/addProduct'
import ProductList from './modules/admin/addProduct/allProductList'
import CategoriesList from './modules/admin/category/CategoriesList'
import Logout from './modules/admin/logout/logout'

class AppRoutes extends Component{
    render(){
        return(
            <>
            <Switch>
                <Route exact path="/" component={Login} />
                <PrivateRoute path="/admin/dashboard" component={Dashbaord}/>
                <PrivateRoute path="/admin/add-category" component={AddCategory}/>
                <PrivateRoute path="/admin/add-sub-category" component={AddSubCategory}/>
                <PrivateRoute path="/admin/categories-list" component={CategoriesList}/>
                <PrivateRoute path="/admin/add-product" component={Addproduct}/>
                <PrivateRoute path="/admin/product-list" component={ProductList}/>
                <PrivateRoute path="/admin/logout" component={Logout}/>
                {/* Redirect To Login screen after logout */}
                <Route exact path="/admin/login" render={() => <Redirect to="" />} />
            </Switch>
            </>
        )
    }
}

export default AppRoutes;