import axios from 'axios'
import getApiUrl from '../helpers/apiURLs'
import * as commonServices from '../services/commonServices'
import * as productReqType from '../modules/admin/addProduct/actionTypes/product.action'
import {database,storage,auth} from '../helpers/firebase-config'

export const getSubCatItem = (dispatch,payload) => {
    dispatch(commonServices.setLoader(true));
    const getSubCatQuery = database.ref('Product-Database').child('Sub-Category').orderByChild("parentID").equalTo(payload);
    getSubCatQuery.once('value').then( (snapshot) => {
        let SubCat = snapshot.val() || {}
        let subcategoryArr = []
        for (const key of Object.keys(SubCat)) {
            var data = SubCat[key]
            data.keyNode = key
            subcategoryArr.push(data);
        }
        dispatch({type:productReqType.GETSUBCATITEM, setSubCat:subcategoryArr})
        dispatch(commonServices.setLoader(false));
    }).catch(err=>{
        dispatch(commonServices.setLoader(false));
    })
}

export const addProduct = (dispatch,UploadImage,payload) => {
    console.log(UploadImage.name)
    dispatch(commonServices.setLoader(true));
    let storeImageName = []
    for(const key in UploadImage){
        storeImageName.push(UploadImage[key].name)
    }
    function getDownloadURL(imgArr){
        return new Promise((resolve) => {
            storage.ref().child('product-images/' + imgArr.name).put(imgArr).then((snapshot) => {
                snapshot.ref.getDownloadURL().then(function(url) {
                    let ImgURL = url
                    resolve(ImgURL)
                }).catch(err => {
                    console.log('Error')
                    dispatch(commonServices.setLoader(false));
                    dispatch({type: productReqType.PRODUCT_ADDED_ERROR, ProductAddedError: true});
                });
            }).catch(err=>{
                dispatch(commonServices.setLoader(false));
                dispatch({type: productReqType.PRODUCT_ADDED_ERROR, ProductAddedError: true});
            })
        })
    }
    async function uploadImg(getUploadimg){
        var newArr = []
        for(const ele of getUploadimg){
            const newURL = await getDownloadURL(ele)
            newArr.push(newURL)
        }
        payload.imgURLs = newArr
        payload.imgName = storeImageName
        setProductData(dispatch,payload);
    }
    uploadImg(UploadImage)
}

const setProductData = (dispatch,payload) => {
    auth.currentUser.getIdToken().then(token => {
        if (token) {
            const url = getApiUrl('product','addProducts') + ".json?auth=" + token
            axios.post(url,payload).then(response => {
                dispatch(commonServices.setLoader(false));
                dispatch({type: productReqType.PRODUCT_ADDED, ProductAdded: true});
                setTimeout(() => {
                    dispatch({type: productReqType.PRODUCT_ADDED, ProductAdded: false});
                  }, 2000)
            }).catch(err=>{
                dispatch(commonServices.setLoader(false));
                dispatch({type: productReqType.PRODUCT_ADDED_ERROR, ProductAddedError: true});
            })
        }
    })
}

export const getProductList = (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const url = getApiUrl('product','getProducts') + ".json"
    axios.get(url).then(response => {
    if(response.status){
        let proListArr = []
        let respnseData = response.data || {}
        for (const key of Object.keys(respnseData)) {
            let Productdata = respnseData[key]
            Productdata.keyNode = key 
            proListArr.push(Productdata);
        }
        dispatch({type:productReqType.GET_PRODUCT_LIST, ProductList:proListArr})
        dispatch(commonServices.setLoader(false))
    }
    else{
        dispatch(commonServices.setLoader(false))
    }
    }).catch(err => dispatch(dispatch(commonServices.setLoader(false))))
}

export const DeleteProduct = (dispatch,getId,getnode,SubCatIDStatus) => {
    dispatch(commonServices.setLoader(true));
    let getMatchProduct;
    if(SubCatIDStatus === true){
        getMatchProduct = database.ref('Product-Database').child('Products').orderByChild("SubCatID").equalTo(getId);  
    }else{
        getMatchProduct = database.ref('Product-Database').child('Products').orderByChild("CatID").equalTo(getId); 
    }
    getMatchProduct.once('value').then( (snapshot) => {
        // Get Same Sub Cat Products
        let MatchProduct = snapshot.val() || {}
        let MatchProductArr = []
        for (const key of Object.keys(MatchProduct)) {
            var data = MatchProduct[key]
            data.keyNode = key
            MatchProductArr.push(data);
        }

        // Filter Producs according to key Node
        let filterData = MatchProductArr.filter((item) => {
            return item = item.keyNode.includes(getnode)
        })

        // Get Image Name
        let getImgURLs = filterData[0].imgName

        function DeleteImages(imgArr){
            return new Promise((resolve) => {
                var desertRef = storage.ref().child('product-images/' + imgArr);
                desertRef.delete().then(() => {
                    resolve()
                }).catch(err => {
                    database.ref('Product-Database').child('Products').child(getnode).remove()
                    getProductList(dispatch)
                    dispatch(commonServices.setLoader(false));
                    dispatch(commonServices.setSuccessMsg(true));
                    setTimeout(() => {
                        dispatch(commonServices.setSuccessMsg(false));
                    }, 2000)
                })
            })
        }

        async function DeleteImg(getImgURLs){
            for(const ele of getImgURLs){
                await DeleteImages(ele)
            }
            database.ref('Product-Database').child('Products').child(getnode).remove()
            getProductList(dispatch)
            dispatch(commonServices.setLoader(false));
            dispatch(commonServices.setSuccessMsg(true));
            setTimeout(() => {
                dispatch(commonServices.setSuccessMsg(false))
            }, 2000)
        }
        
        DeleteImg(getImgURLs)

    }).catch(err=>{
        dispatch(commonServices.setLoader(false));
    })
}

export default {
    getSubCatItem,
    addProduct,
    getProductList,
    DeleteProduct
}